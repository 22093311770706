import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "@wordpress/block-library/build-style/style.css"
import "@wordpress/block-library/build-style/theme.css"


import Layout from "../components/layout"
import SEO from "gatsby-plugin-wpgraphql-seo";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import ContactForm from "../components/contact-form";

const BlogPostTemplate = ({ data: { page } }) => {

  const featuredImage = page.featuredImage && getImage(page.featuredImage.node.localFile);

  return (
    <Layout>
      <SEO post={page} />

      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          {/* if we have a featured image for this post let's display it */}
          {featuredImage && (
            <GatsbyImage
              image={featuredImage}
              alt={featuredImage.alt}
            />
          )}
          {!page.gatsby.hidetitle && (
            <h1 className="text-6xl" itemProp="headline">{parse(page.title)}</h1>
          )}
        </header>

        {!!page.content && (
          <section itemProp="articleBody" className="mt-4 prose prose-xl max-w-4xl">{parse(page.content)}</section>
        )}
      </article>
      <div className="mt-10">
        <ContactForm />
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query PageById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")
      gatsby {
        hidetitle
      }

      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
      
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`
